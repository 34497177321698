import React from 'react';
import { Button, ButtonGroup, ButtonToolbar } from 'rsuite';

const CompanyPage: React.FC<{reference?:any, onClick?:any}> = ({reference = null, onClick}) => {

  

  return (
    <section ref={reference} className="company-section py-20 mb-0 lg:mb-20">
      <div className="section-wrapper">
        <div className="container mx-auto px-4 h-full">

          <div className="row mb-10">
            <div className="row-inner">
              <h3 className="title capitalize text-center text-3xl text-[--rs-red-500] mb-3">Our Company</h3>
              <p className="description text-center mx-auto">We are a leading consulting firm dedicated to providing expert advice and research across various fields. Our mission is to help organizations navigate complex challenges and achieve their goals through tailored strategies and innovative solutions. With a focus on driving growth and enhancing efficiency, we deliver exceptional value and support to our clients.</p>
            </div>
          </div>

          <div className="row">
            <div className="row-inner">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-20">
                <div className="images-block">
                  <div className="block-wrapper relative">
                    <img className="w-full mx-auto lg:mx-0 relative lg:max-w-[80%]" src="https://validthemes.net/site-template/biness/assets/img/about/4.jpg" alt="about" />
                    <img className="absolute hidden sm:block w-[300px] z-10 right-20 bottom-10 lg:right-0 lg:-bottom-20 border-solid border-[10px] border-white shadow-xl" src="https://validthemes.net/site-template/biness/assets/img/about/5.jpg" alt="about" />
                  </div>
                </div>
                <div className="text-block">
                  <div className="block-wrapper h-full">
                    <div className="flex flex-col justify-center h-full gap-5">
                      <h3 className="text-4xl text-center lg:text-left">We’re Constantly Improving Our Skills to Fulfill Projects</h3>
                      <p className="font-semibold text-gray-600">With 4 years of experience and over 5,000 clients worldwide, we deliver specialized services in innovation and AI, marketing, feasibility studies, administration, quality assurance, media, ERP systems, health and fitness, project development, logistics, advertising, and fine arts.</p>
                      <p>At Daeem Consulting, we provide comprehensive and specialized consulting services across a diverse range of sectors to drive growth, innovation, and operational excellence. 
                        Our expert team delivers tailored solutions in the fields of innovation and artificial intelligence, marketing, feasibility studies, administration, quality assurance, media, ERP systems, health and fitness, project development, logistics, advertising, and fine arts. 
                          We partner with organizations to enhance efficiency, optimize strategies, and achieve measurable results, ensuring success and sustainability in an ever-evolving marketplace.</p>
                      <Button onClick={() => onClick('contact')} appearance="primary" color="red" className="capitalize w-1/2 lg:w-1/2 xl:w-1/2 mx-auto lg:mx-0 !rounded-full h-14 !font-semibold !text-lg" >Get in touch</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyPage;
