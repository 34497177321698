import { HiChatBubbleLeftRight } from "react-icons/hi2";

const ProcessSection: React.FC<{reference?:any}> = ({reference = null}) => {

  return (
    <div ref={reference} className="process-section relative py-20 before:absolute before:left-0 before:top-0 before:right-0 before:bottom-0 before:bg-black before:opacity-25 bg-fixed bg-[url('https://validthemes.net/site-template/biness/assets/img/banner/7.jpg')] bg-no-repeat bg-cover">
      <div className="section-wrapper relative">
        <div className="container mx-auto px-4 h-full">

          <div className="row mb-10">
            <div className="row-inner">
              <h3 className="title capitalize text-center text-3xl text-[--rs-red-500] mb-3">Our Process</h3>
              <p className="description text-center mx-auto text-white">We begin by engaging in in-depth discussions to understand your goals and challenges. Next, we analyze data and research to uncover insights and opportunities. Finally, we provide actionable recommendations and strategies, tailored to meet your objectives and drive successful outcomes.</p>
            </div>
          </div>

          <div className="row">
            <div className="row-inner">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-0 place-items-center text-white">
                <div className="discuss flex justify-center items-center gap-5 flex-col">
                  <div className="icon w-36 h-36 relative border-dashed border border-gray-400 bg-black bg-opacity-50 rounded-full flex justify-center items-center">
                    <HiChatBubbleLeftRight className="text-4xl" />
                    <div className="absolute -top-2 -right-2 border-[5px] border-opacity-50 border-white w-14 h-14 rounded-full overflow-hidden">
                      <span className="font-medium text-xl bg-[--rs-red-500] w-full h-full flex justify-center items-center">1</span>
                    </div>
                  </div>
                  <span className="capitalize text-2xl font-bold">discuss</span>
                </div>
                <div className="analysis flex justify-center items-center gap-5 flex-col lg:mb-40">
                  <div className="icon w-36 h-36 relative border-dashed border border-gray-400 bg-black bg-opacity-50 rounded-full flex justify-center items-center">
                    <HiChatBubbleLeftRight className="text-4xl" />
                    <div className="absolute -top-2 -right-2 border-[5px] border-opacity-50 border-white w-14 h-14 rounded-full overflow-hidden">
                      <span className="font-medium text-xl bg-[--rs-red-500] w-full h-full flex justify-center items-center">2</span>
                    </div>
                  </div>
                  <span className="capitalize text-2xl font-bold">Analysis</span>
                </div>
                <div className="implement flex justify-center items-center gap-5 flex-col">
                  <div className="icon w-36 h-36 relative border-dashed border border-gray-400 bg-black bg-opacity-50 rounded-full flex justify-center items-center">
                    <HiChatBubbleLeftRight className="text-4xl" />
                    <div className="absolute -top-2 -right-2 border-[5px] border-opacity-50 border-white w-14 h-14 rounded-full overflow-hidden">
                      <span className="font-medium text-xl bg-[--rs-red-500] w-full h-full flex justify-center items-center">3</span>
                    </div>
                  </div>
                  <span className="capitalize text-2xl font-bold">Implement</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )

}

export default ProcessSection;