import { Button, ButtonGroup, ButtonToolbar } from 'rsuite';



const MainSection: React.FC<{reference?:any, onClick?:any}> = ({reference = null, onClick}) => {
  return (
    <section ref={reference} className="main-section">
      <div className="section-wrapper relative before:absolute before:left-0 before:top-0 before:right-0 before:bottom-0 before:bg-black before:opacity-25 before:z-[0] bg-[url('https://validthemes.net/site-template/biness/assets/img/banner/4.jpg')] h-screen bg-no-repeat bg-cover">
        <div className="container mx-auto px-4 h-full relative">
          <div className="grid grid-cols-12 pb-0 lg:pb-0 content-center h-full text-white">
            <div className="col-span-10 col-start-2 lg:col-span-7 lg:col-start-3">
              <div className="row mb-8">
                <h3 className="title text-[55px] text-center leading-none mb-5 capitalize">
                  We Will Help You To <b className="text-[--rs-red-500]">Grow Up</b> Your Business
                </h3>
                <p className="subtitle text-[18px] leading-normal text-center font-normal" style={{ color: "rgba(254,254,254,0.85)!important" }}>
                We are Experts in business and finance consulting, IT solutions, and more. With 4 years of experience and over 5,000 clients worldwide, we specialize in innovation and AI, marketing, feasibility studies, administration, quality assurance, media, ERP systems, health and fitness, project development, logistics, advertising, and fine arts.
                </p>
              </div>
              <div className="flex justify-center mb-5">
                <Button onClick={() => onClick('contact')} className="w-full lg:w-1/2 !rounded-full !text-white !text-xl !font-semibold" appearance="ghost" color="red">Get in touch</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MainSection