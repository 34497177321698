import CompanyPage from "./sections/company.section"
import ContactSection from "./sections/contact.section"
import MainSection from "./sections/main.section"
import MapSection from "./sections/map.section"
import ProcessSection from "./sections/process.section"
import ServiceSection from "./sections/service.section"



const HomePage:React.FC<{sectionRefs?:any, onClick?:any}> = ({sectionRefs=null, onClick}) => {
  return(
    <div className="home-page">
      <div className="wrapper">
        <MainSection onClick={(e:any) => onClick(e)} reference={sectionRefs?.main} />
        <CompanyPage onClick={(e:any) => onClick(e)} reference={sectionRefs?.company} />
        <ServiceSection reference={sectionRefs?.service} />
        <ProcessSection reference={sectionRefs?.process} />
        <ContactSection reference={sectionRefs?.contact} />
        <MapSection reference={sectionRefs?.map} />
      </div>
    </div>
  )
}

export default HomePage