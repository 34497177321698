import { IoCarSportOutline } from "react-icons/io5";
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Autoplay, } from 'swiper/modules';
import { 
  RiMegaphoneLine,
  RiOpenaiLine,
  RiScalesLine,
  RiBuilding2Line,
  RiStarLine,
  RiDvLine,
  RiBuilding4Line,
  RiHeartPulseLine,
  RiFileChartLine,
  RiAdvertisementLine,
  RiInstanceLine,
  RiArtboardLine
} from "react-icons/ri";

const ServiceSection: React.FC<{reference?:any}> = ({reference = null}) => {

  const swiperProps: SwiperProps = {
    modules: [Autoplay,],
    loop: true,
    freeMode: false,
    spaceBetween: 30,
    //slidesPerView: 3,
    grabCursor: true,
    autoplay: { delay: 5000 },
    breakpoints: {
      500: {
        slidesPerView: 1.5,
        spaceBetween: 40
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 40
      },
      1024: {
        slidesPerView: 2.5,
        spaceBetween: 40
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 40
      }
    }
  }


  const TradingCard: React.FC<{ title?: string, description?: string, icon?: any }> = ({ title, description, icon }) => {
    return (
      <div className="shadow-lg bg-white rounded-lg">
        <div className="card-wrapper">
          <div className="p-5 flex flex-col justify-center items-center">
            <div className="w-20 h-20 mb-2 flex justify-center items-center rounded-full border-[2px] border-gray-200 border-dashed">
              {icon}
            </div>
            <h3 className="title text-base h-20 text-center">{title}</h3>
            <p className="text-md lg:h-24">{description}</p>
          </div>
        </div>
      </div>
    )
  }


  return (
    <section ref={reference} className="service-section bg-gray-100 py-20">
      <div className="section-wrapper">
        <div className="container mx-auto px-4 h-full">

          <div className="row mb-10">
            <div className="row-inner">
              <h3 className="title capitalize text-center text-3xl text-[--rs-red-500] mb-3">Our Services</h3>
              <p className="description text-center mx-auto">We offer specialized consulting and research to help organizations achieve their goals. Our expertise spans a range of areas, delivering tailored solutions that drive growth, enhance efficiency, and ensure successful outcomes.</p>
            </div>
          </div>

          <div className="row">
            <div className="row-inner">
              <Swiper {...swiperProps}>
                <SwiperSlide className="pb-5">
                  <TradingCard
                    icon={<RiOpenaiLine className="text-5xl text-[--rs-red-500]" />}
                    title="Consulting and Studies in the Field of Innovation and Artificial Intelligence"
                    description="Expert guidance in harnessing innovation and AI technologies. We help organizations develop AI strategies, conduct feasibility studies, and integrate advanced technologies to drive growth and enhance operational efficiency." />
                </SwiperSlide>
                <SwiperSlide className="pb-5">
                  <TradingCard
                    icon={<RiMegaphoneLine className="text-5xl text-[--rs-red-500]" />}
                    title="Marketing consulting and studies"
                    description="Tailored marketing strategies and research to boost your brand's presence and effectiveness. We analyze market trends and craft strategies to optimize marketing efforts and achieve measurable results." />
                </SwiperSlide>
                <SwiperSlide className="pb-5">
                  <TradingCard
                    icon={<RiScalesLine className="text-5xl text-[--rs-red-500]" />}
                    title="Feasibility study consulting and studies"
                    description="Comprehensive feasibility studies to assess the viability of projects and investments. Our analysis provides valuable insights to inform decision-making and ensure project success." />
                </SwiperSlide>
                <SwiperSlide className="pb-5">
                  <TradingCard
                    icon={<RiBuilding2Line className="text-5xl text-[--rs-red-500]" />}
                    title="Administrative consulting and studies"
                    description="Enhancing organizational efficiency through expert administrative consulting. We streamline processes, optimize operations, and improve overall administrative functions to boost productivity." />
                </SwiperSlide>
                <SwiperSlide className="pb-5">
                  <TradingCard
                    icon={<RiStarLine className="text-5xl text-[--rs-red-500]" />}
                    title="Quality, calibration, and evaluation consulting"
                    description="Specialized consulting in quality assurance, calibration, and evaluation. We ensure compliance with industry standards and enhance operational precision and effectiveness." />
                </SwiperSlide>
                <SwiperSlide className="pb-5">
                  <TradingCard
                    icon={<RiDvLine className="text-5xl text-[--rs-red-500]" />}
                    title="Media consulting and studies"
                    description="Expert media consulting and research to refine media strategies and boost performance. We provide insights into media trends and develop strategies to maximize engagement and impact." />
                </SwiperSlide>
                <SwiperSlide className="pb-5">
                  <TradingCard
                    icon={<RiBuilding4Line className="text-5xl text-[--rs-red-500]" />}
                    title="Enterprise resource planning consulting"
                    description="Streamlining business processes with ERP consulting. We assist in implementing ERP systems to improve data accuracy, operational efficiency, and overall business performance." />
                </SwiperSlide>
                <SwiperSlide className="pb-5">
                  <TradingCard
                    icon={<RiHeartPulseLine className="text-5xl text-[--rs-red-500]" />}
                    title="Health club and fitness consulting"
                    description="Expertise in health club and fitness consulting to optimize operations, enhance member experiences, and boost performance. We provide strategies for growth and efficiency in the fitness industry." />
                </SwiperSlide>
                <SwiperSlide className="pb-5">
                  <TradingCard
                    icon={<RiFileChartLine className="text-5xl text-[--rs-red-500]" />}
                    title="Project development consulting"
                    description="Supporting organizations in project development with expert consulting services. We guide project planning, execution, and management to achieve successful outcomes and meet objectives." />
                </SwiperSlide>
                <SwiperSlide className="pb-5">
                  <TradingCard
                    icon={<RiAdvertisementLine className="text-5xl text-[--rs-red-500]" />}
                    title="Advertising consulting and studies"
                    description="Specializing in advertising consulting and research to develop effective advertising strategies. We analyze market trends and create campaigns that drive results and maximize return on investment." />
                </SwiperSlide>
                <SwiperSlide className="pb-5">
                  <TradingCard
                    icon={<RiInstanceLine className="text-5xl text-[--rs-red-500]" />}
                    title="Logistics consulting"
                    description="Offering logistics consulting to enhance supply chain management and operational efficiency. We provide solutions for optimizing logistics strategies and improving overall performance." />
                </SwiperSlide>
                <SwiperSlide className="pb-5">
                  <TradingCard
                    icon={<RiArtboardLine className="text-5xl text-[--rs-red-500]" />}
                    title="Fine arts consulting"
                    description="Providing fine arts consulting to support artists, galleries, and collectors. We offer expertise in art market trends, acquisitions, and strategic planning to enhance the value and impact of fine arts initiatives." />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default ServiceSection;