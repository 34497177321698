


const MapSection: React.FC<{reference?:any}> = ({reference = null}) => {
  return (
    <div ref={reference} className="map-section">
      <div className="section-wrapper">
      <iframe className="w-full h-[30rem] grayscale" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.3980266155613!2d55.32662860000001!3d25.257192699999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5cda1f02ff6b%3A0x1cceb82e34fd3773!2sGroove%20Investments%20LLC!5e0!3m2!1sfr!2stn!4v1721683511299!5m2!1sfr!2stn"></iframe>
      </div>
    </div>
  )
}

export default MapSection