import React from "react";


const NavBar: React.FC<{onClick?:any}> = ({ onClick }) => {

  return (
    <div className="navbar shadow-lg z-[1000] w-full">
      <div className="navbar-wrapper relative">
        <span className="absolute left-0 top-0 right-0 bottom-0 bg-white opacity-70"></span>
        <div className="container mx-auto px-4 py-1 relative">
          <div className="flex items-center">
            <div className="logo self-start">
              <img className="w-32" src={require('./../../assets/img/logo.png')} alt="logo-rakz" />
            </div>
            <div className="menu w-full flex justify-center">
              <ul className="flex m-0 p-0 self-center text-sm text-black gap-5 font-medium">
                <li onClick={() => onClick('home')} className="m-0 p-0 cursor-pointer">Home</li>
                <li onClick={() => onClick('company')} className="m-0 p-0 cursor-pointer">Company</li>
                <li onClick={() => onClick('service')} className="m-0 p-0 cursor-pointer">Service</li>
                <li onClick={() => onClick('process')} className="m-0 p-0 cursor-pointer">Process</li>
                <li onClick={() => onClick('contact')} className="m-0 p-0 cursor-pointer">Contact</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavBar