import 'rsuite/dist/rsuite.min.css';
import 'swiper/swiper-bundle.css';
import "./assets/scss/app.scss"
import HomePage from "./pages/home/home.page";
import NavBar from "./common/header/navbar.common";
import Footer from './common/footer/footer.common';
import React from 'react';

const App: React.FC = () => {

  const sectionRefs: any = {
    main: React.useRef(null),
    contact: React.useRef(null),
    company: React.useRef(null),
    service: React.useRef(null),
    process: React.useRef(null),
    map: React.useRef(null),
  };

  const scrollToSection = (section: any) => {
    sectionRefs[section]?.current?.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <div className="App">
      <NavBar onClick={scrollToSection} />
      <HomePage sectionRefs={sectionRefs} onClick={scrollToSection} />
      <Footer onClick={scrollToSection} />
    </div>
  );
}

export default App;
